import WarningOutlinedIcon from "@mui/icons-material/WarningTwoTone";
import { Alert, AlertTitle } from "@mui/material";
import { AttendanceScoreProfileResponse } from "@src/appV2/AttendanceScore/api/useGetAttendanceScoreProfile";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { USER_EVENTS } from "@src/constants";

export function LatenessDeductionWarningAlert(props: {
  attendaceScoreProfile: AttendanceScoreProfileResponse;
}) {
  const { attendaceScoreProfile } = props;
  const {
    latenessPointsDeductedInLast30Days = 0,
    score,
    accountStatus,
    suspensionCount,
  } = attendaceScoreProfile;
  useLogEffect(USER_EVENTS.SHOW_TIMELINESS_DEDUCTION_WARNING, {
    score,
    latenessPointsDeductedInLast30Days,
    accountStatus,
    suspensionCount,
  });
  return (
    <Alert severity="warning" icon={<WarningOutlinedIcon sx={{ color: "warning.dark" }} />}>
      <AlertTitle sx={{ fontWeight: "bold", color: "warning.dark" }}>Warning!</AlertTitle>
      {`You lost ${latenessPointsDeductedInLast30Days} points due to timeliness deductions in the last month.`}
    </Alert>
  );
}
