import { isDefined } from "@clipboard-health/util-ts";
import { ATeamHcpStatus } from "@src/appV2/Rankings/types";
export interface AccountRestrictedMessageOptions {
  aTeamStatus: ATeamHcpStatus;
  aTeamWorkplacesCount: number;
  formattedDate: string | undefined;
}

export const favoritePolicyEnrolledMessage =
  "An Attendance Score of 0 or below will result in account restriction. 100 is the max Attendance Score.";

export function getAccountRestrictedMessage(parameters: AccountRestrictedMessageOptions): string {
  const { formattedDate, aTeamStatus, aTeamWorkplacesCount } = parameters;
  const baseMessage = isDefined(formattedDate)
    ? `Your account is restricted due to an Attendance Score of 0 or below until ${formattedDate}.`
    : `Your account is restricted due to an Attendance Score of 0 or below.`;
  let additionalInfo =
    "While restricted, you may only book shifts at workplaces where you are a favorite worker.";
  if (aTeamStatus === ATeamHcpStatus.A_TEAM) {
    additionalInfo =
      "While restricted, you may book at workplaces where you have Priority Plus status.";
  } else if (aTeamWorkplacesCount > 0 && aTeamStatus !== ATeamHcpStatus.OFF) {
    additionalInfo =
      "While restricted, you may book at workplaces where you have Priority Plus status or are a Favorite professional.";
  }

  return `${baseMessage} ${additionalInfo}`;
}
