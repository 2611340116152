import { UseQueryOptions, UseQueryResult, useQuery } from "@tanstack/react-query";

import {
  AttendanceScoreCancellationPolicyResponse,
  getAttendanceScoreCancellationPolicy,
  getAttendanceScoreCancellationPolicyPath,
} from "./getAttendanceScoreCancellationPolicy";

export function useAttendanceScoreCancellationPolicy(
  leadTime: number,
  canCancelWithoutPenalty: boolean,
  workplaceId?: string,
  userId?: string,
  options: UseQueryOptions<AttendanceScoreCancellationPolicyResponse> = {}
): UseQueryResult<AttendanceScoreCancellationPolicyResponse> {
  const query = {
    agentId: userId ?? "",
    leadTime,
    workplaceId: workplaceId ?? "",
  };
  return useQuery<AttendanceScoreCancellationPolicyResponse>(
    [getAttendanceScoreCancellationPolicyPath(query)],
    () => getAttendanceScoreCancellationPolicy(query),
    { enabled: !canCancelWithoutPenalty && Boolean(userId), ...options }
  );
}
